

$font-family-sora: var(--font-sora);
$font-family-inter: var(--font-inter);

$line-height-0: 48px;
$line-height-1: 56px;
$line-height-2: 64px;
$line-height-3: 44px;
$line-height-4: 40px;
$line-height-5: 36px;
$line-height-6: 32px;
$line-height-7: 28px;
$line-height-8: 24px;
$line-height-9: 20px;
$line-height-10: 18px;
$line-height-11: 16px;
$line-height-12: 14px;
$line-height-13: auto;
$line-height-14: 34px;
$line-height-15: 22px;
$line-height-16: 80px;
$line-height-17: 52px;

$font-weight-sora-regular: 400; // Regular
$font-weight-sora-semibold: 600; // SemiBold
$font-weight-sora-bold: 700; // Bold
$font-weight-sora-extrabold: 800; // ExtraBold
$font-weight-sora-light: 300; // Light
$font-weight-inter-regular: 400; // Regular
$font-weight-inter-medium: 500; // Medium
$font-weight-inter-semibold: 600; // SemiBold
$font-weight-inter-italic: italic; // Italic
$font-weight-inter-bold: 700; // Bold

$font-size-0: 10px;
$font-size-1: 12px;
$font-size-2: 14px;
$font-size-3: 16px;
$font-size-4: 18px;
$font-size-5: 20px;
$font-size-6: 24px;
$font-size-7: 28px;
$font-size-8: 30px;
$font-size-9: 32px;
$font-size-10: 36px;
$font-size-11: 40px;
$font-size-12: 44px;
$font-size-13: 48px;
$font-size-14: 52px;
$font-size-15: 60px;
$font-size-16: 72px;

$letter-spacing-0: -2%; //review
$letter-spacing-1: 0%;
$letter-spacing-2: 1px;


$paragraph-spacing-0: 0px;
$paragraph-spacing-1: 60px;

$paragraph-indent-0:0px;

$text-case-none: none;
$text-case-uppercase: uppercase;

$text-decoration-none: none;
$text-decoration-underline: underline;
$text-decoration-line-through: line-through;

.display {
  &-regular {
      font-family: $font-family-sora;
      font-weight: $font-weight-sora-regular;
      line-height: $line-height-0;
      letter-spacing: $letter-spacing-0;
      text-indent: $paragraph-spacing-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-12;

    @media (min-width: 768px) and (max-width: 1023px) { 
      line-height: $line-height-1;
      text-transform: $text-case-none;
      font-size: $font-size-16;
    }
           
    @media (min-width: 1024px) { 
    line-height: $line-height-2;
    font-size: $font-size-15;
    }

  }
  &-semibold {
      font-family: $font-family-sora;
      font-weight: $font-weight-sora-semibold;
      line-height: $line-height-0;
      letter-spacing: $letter-spacing-0;
      text-indent: $paragraph-spacing-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-12;
    
    @media (min-width: 768px) and (max-width: 1023px) { 
      line-height: $line-height-1;
      font-size: $font-size-14;
    }
    
    @media (min-width: 1024px) { 
      line-height: $line-height-2;
      font-size: $font-size-15;
    }
  }
  &-bold{
      font-family: $font-family-sora;
      font-weight: $font-weight-sora-bold;
      line-height: $line-height-0;
      letter-spacing: $letter-spacing-0;
      text-indent: $paragraph-spacing-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-12;
    
    @media (min-width: 768px) and (max-width: 1023px) { 
      line-height: $line-height-1;
      font-size: $font-size-14;
    }
    
    @media (min-width: 1024px) { 
      line-height: $line-height-2;
      font-size: $font-size-15;
    }
  
  }
  &-extrabold {
      font-family: $font-family-sora;
      font-weight: $font-weight-sora-extrabold;
      line-height: $line-height-0;
      letter-spacing: $letter-spacing-0;
      text-indent: $paragraph-spacing-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-12;
   
      @media (min-width: 768px) and (max-width: 1023px) {
        line-height: $line-height-1;
        font-size: $font-size-14;
      }
      
      @media (min-width: 1024px) { 
      line-height: $line-height-2;
      text-indent: $paragraph-spacing-1;
      font-size: $font-size-15;
      }
   
  }
}

.heading {
  &-h1 {
    &-regular {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-regular;
        line-height: $line-height-3;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-10;

      @media (min-width: 768px) {
        line-height: $line-height-0; 
        font-size: $font-size-11; 
      }
    }
    &-semibold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-semibold;
        line-height: $line-height-3;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-10;
      
      @media (min-width: 768px) {
        line-height: $line-height-0; 
        font-size: $font-size-11; 
      }
    }
    &-bold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-bold;
        line-height: $line-height-3;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-10;
      
      @media (min-width: 768px) {
        line-height: $line-height-0; 
        font-size: $font-size-11; 
      }
    }
    &-extrabold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-extrabold;
        line-height: $line-height-3;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-10;

      @media (min-width: 768px) {
        line-height: $line-height-0; 
        font-size: $font-size-11; 
      }
    }
  }

  &-h2 {
    &-regular {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-regular;
        line-height: $line-height-4;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-9;

      @media (min-width: 768px) {
        line-height: $line-height-3; 
        font-size: $font-size-11; 
      }
    }
    &-semibold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-semibold;
        line-height: $line-height-4;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-9;
      
      @media (min-width: 768px) {
        letter-spacing: $letter-spacing-0;
        line-height: $line-height-3; 
        font-size: $font-size-10; 
      }
    }
    &-bold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-bold;
        line-height: $line-height-4;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-9;

      @media (min-width: 768px) {
        line-height: $line-height-3;
        font-size: $font-size-10; 
        letter-spacing: $letter-spacing-0;
      }
    }
    &-extrabold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-extrabold;
        line-height: $line-height-4;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-10;
      
      @media (min-width: 768px) {
        line-height: $line-height-3;
        font-size: $font-size-10; 
      }
    }
  }

  &-h3 {
    &-regular {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-regular;
        line-height: $line-height-5;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-7;
      
      @media (min-width: 768px) {
        line-height: $line-height-4;
        font-size: $font-size-9; 
      }
    }
    &-semibold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-semibold;
        line-height: $line-height-5;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-7;
      
      @media (min-width: 768px) {
        line-height: $line-height-4;
        font-size: $font-size-9; 
      }
    }
    &-bold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-bold;
        line-height: $line-height-5;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-7;

      @media (min-width: 768px) {
        line-height: $line-height-4;
        font-size: $font-size-9; 
      }
    }
    &-extrabold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-extrabold;
        line-height: $line-height-5;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-7;
      
      @media (min-width: 768px) {
        line-height: $line-height-4;
        font-size: $font-size-9; 
      }
    }
  }

  &-h4 {
    &-regular {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-regular;
        line-height: $line-height-6;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-6;
  
      @media (min-width: 768px) {
        line-height: $line-height-5;
        font-size: $font-size-7; 
      }
    }
    &-semibold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-semibold;
        line-height: $line-height-6;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-6;
      
      @media (min-width: 768px) {
        line-height: $line-height-5;
        font-size: $font-size-7; 
      }
    }
    &-bold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-bold;
        line-height: $line-height-6;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-6;

      @media (min-width: 768px) {
        line-height: $line-height-5;
        font-size: $font-size-7; 
      }
    }
    &-extrabold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-extrabold;
        line-height: $line-height-6;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-7;
      
      @media (min-width: 768px) {
        line-height: $line-height-5;
        font-size: $font-size-7; 
      }
    }
  }

  &-h5 {
    &-regular {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-regular;
        line-height: $line-height-7;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-5;
    
      @media (min-width: 768px) {
        line-height: $line-height-6;
        font-size: $font-size-6; 
      }
    }
    &-semibold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-semibold;
        line-height: $line-height-7;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-5;
      
      @media (min-width: 768px) {
        line-height: $line-height-6;
        font-size: $font-size-6; 
      }
    }
    &-bold {
      
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-bold;
        line-height: $line-height-7;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-5;
      
      @media (min-width: 768px) {
        line-height: $line-height-6;
        font-size: $font-size-6; 
      }
    }
    &-extrabold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-extrabold;
        line-height: $line-height-7;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-5;
      
      @media (min-width: 768px) {
        line-height: $line-height-6;
        font-size: $font-size-6; 
      }
    }
  }

  &-h6 {
    &-regular {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-regular;
        line-height: $line-height-8;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-4;
      
      @media (min-width: 768px) {
        line-height: $line-height-7;
        font-size: $font-size-5; 
      }
    }
    &-semibold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-semibold;
        line-height: $line-height-8;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-4;

      @media (min-width: 768px) {
        line-height: $line-height-7;
        font-size: $font-size-5; 
      }
    }
    &-bold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-bold;
        line-height: $line-height-8;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-4;
      
      @media (min-width: 768px) {
        line-height: $line-height-7;
        font-size: $font-size-5; 
      }
    }
    &-extrabold {
        font-family: $font-family-sora;
        font-weight: $font-weight-sora-extrabold;
        line-height: $line-height-8;
        letter-spacing: $letter-spacing-0;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-4;
      
      @media (min-width: 768px) {
        line-height: $line-height-7;
        font-size: $font-size-5; 
      }
    }
  }
}

.paragraph {
  &-large {
    &-regular {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-regular;
      line-height: $line-height-7;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-4;
    }
    &-medium {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-medium;
      line-height: $line-height-7;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-4;
    }
    &-semibold {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-semibold;
      line-height: $line-height-7;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-4;
    }
    &-special {
      &-underline{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-7;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-underline;
        text-transform: $text-case-none;
        font-size: $font-size-4;
      }
      &-strikethrough{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-7;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-line-through;
        text-transform: $text-case-none;
        font-size: $font-size-4;
      }
     &-Italics{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-italic;
        line-height: $line-height-7;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-4;
      }
    }
  }
  &-medium {
    &-regular {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-regular;
      line-height: $line-height-8;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-3;
    }
    &-medium {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-medium;
      line-height: $line-height-8;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-3;
    }
    &-semibold {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-semibold;
      line-height: $line-height-8;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-3;
    }
    &-special {
      &-underline{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-8;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-underline;
        text-transform: $text-case-none;
        font-size: $font-size-3;
      }
      &-strikethrough{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-8;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-line-through;
        text-transform: $text-case-none;
        font-size: $font-size-3;
      }
     &-Italics{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-italic;
        line-height: $line-height-8;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-3;
      }
    }
  }
  &-small {
    &-regular {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-regular;
      line-height: $line-height-9;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-2;
    }
    &-medium {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-medium;
      line-height: $line-height-9;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-2;
    }
    &-semibold {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-semibold;
      line-height: $line-height-9;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-2;
    }
    &-special {
      &-underline{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-9;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-underline;
        text-transform: $text-case-none;
        font-size: $font-size-2;
      }
      &-strikethrough{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-9;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-line-through;
        text-transform: $text-case-none;
        font-size: $font-size-2;
      }
     &-Italics{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-italic;
        line-height: $line-height-9;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-2;
      }
    }
  }
  &-XSmall {
    &-regular {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-regular;
      line-height: $line-height-9;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-1;
    }
    &-medium {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-medium;
      line-height: $line-height-9;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-1;
    }
    &-semibold {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-semibold;
      line-height: $line-height-9;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-1;
    }
    &-special {
      &-underline{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-9;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-underline;
        text-transform: $text-case-none;
        font-size: $font-size-1;
      }
      &-strikethrough{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-9;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-line-through;
        text-transform: $text-case-none;
        font-size: $font-size-1;
      }
     &-Italics{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-italic;
        line-height: $line-height-9;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-2;
      }
    }
  }
  &-Overline {
    &-large{
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-semibold;
      line-height: $line-height-9;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-uppercase;
      font-size: $font-size-2;
    }
    &-small{
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-semibold;
      line-height: $line-height-9;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-uppercase;
      font-size: $font-size-1;
    }

  }
}

.label{
  &-large {
    &-regular {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-regular;
      line-height: $line-height-11;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-3;
      //--- font-size
    }
    &-medium {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-medium;
      line-height: $line-height-11;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-3;
    }
    &-semibold {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-semibold;
      line-height: $line-height-10;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-3;
    }
    &-special {
      &-underline{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-10;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-underline;
        text-transform: $text-case-none;
        font-size: $font-size-3;
      }
      &-strikethrough{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-10;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-line-through;
        text-transform: $text-case-none;
        font-size: $font-size-3;
      }
     &-Italics{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-italic;
        line-height: $line-height-9;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-3;
      }
    }
  }
  &-medium {
    &-regular {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-regular;
      line-height: $line-height-11;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-2;
    }
    &-medium{
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-medium;
      line-height: $line-height-11;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-2;
    }
    &-semibold {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-semibold;
      line-height: $line-height-11;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-2;
    }
    &-special{
      &-underline{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-11;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-underline;
        text-transform: $text-case-none;
        font-size: $font-size-2;
      }
      &-strikethrough{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-11;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-line-through;
        text-transform: $text-case-none;
        font-size: $font-size-2;
      }
     &-Italics{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-italic;
        line-height: $line-height-9;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-2;
      }
    }
  }
  &-small {
    &-regular {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-regular;
      // line-height: $line-height-12;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-1;
    }
    &-medium{
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-medium;
      line-height: $line-height-12;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-1;
    }
    &-semibold {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-semibold;
      line-height: $line-height-12;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-1;
    }
    &-special{
      &-underline{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-12;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-underline;
        text-transform: $text-case-none;
        font-size: $font-size-1;
      }
      &-strikethrough{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-12;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-line-through;
        text-transform: $text-case-none;
        font-size: $font-size-1;
      }
     &-Italics{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-italic;
        line-height: $line-height-12;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-2;
      }
    }
  }
  &-XSmall {
    &-regular {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-regular;
      line-height: $line-height-12;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-0;
    }
    &-medium{
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-medium;
      line-height: $line-height-12;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-0;
    }
    &-semibold {
      font-family: $font-family-inter;
      font-weight: $font-weight-inter-semibold;
      line-height: $line-height-12;
      letter-spacing: $letter-spacing-1;
      text-indent: $paragraph-indent-0;
      text-decoration: $text-decoration-none;
      text-transform: $text-case-none;
      font-size: $font-size-0;
    }
    &-special{
      &-underline{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-12;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-underline;
        text-transform: $text-case-none;
        font-size: $font-size-0;
      }
      &-strikethrough{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-regular;
        line-height: $line-height-12;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-line-through;
        text-transform: $text-case-none;
        font-size: $font-size-0;
      }
     &-Italics{
        font-family: $font-family-inter;
        font-weight: $font-weight-inter-italic;
        line-height: $line-height-12;
        letter-spacing: $letter-spacing-1;
        text-indent: $paragraph-indent-0;
        text-decoration: $text-decoration-none;
        text-transform: $text-case-none;
        font-size: $font-size-0;
      }
    }
  }
}
