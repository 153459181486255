.calendar-arrow {
    display: block;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    background-color: $gray-500;
    left: calc(50% - 10px);
    -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    border-radius: 0 0 0 0.25em;
}

.react-calendar {
    margin-top: 20px;
}

.react-calendar__navigation {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    font-size: 10pt !important;
    font-weight: bold !important;
    font-family: "Sora" !important;

    span {
        text-transform: uppercase !important;
        cursor: default !important;
    }

    button {
        cursor: default !important;
    }
}

.dark{
  .react-calendar__month-view__weekdays__weekday {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
      font-size: 10pt !important;
      font-weight: bold !important;
      font-family: "Sora" !important;
      color: $brand-primary-500 !important;
  
      abbr {
          text-transform: capitalize !important;
          text-decoration: none !important;
      }
  }
}

.light{
  .react-calendar__month-view__weekdays__weekday {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
      font-size: 10pt !important;
      font-weight: bold !important;
      font-family: "Sora" !important;
      color: $brand-primary-600 !important;
  
      abbr {
          text-transform: capitalize !important;
          text-decoration: none !important;
      }
  }
}

.dark {
  .react-calendar__month-view__weekNumbers>div,
  .react-calendar__viewContainer button:not(button.react-calendar__tile--range),
  .react-calendar__navigation button {
      color: $white !important;
      background: transparent;
      padding: 0.5rem !important;
  }
}

.light {
  .react-calendar__month-view__weekNumbers>div,
  .react-calendar__viewContainer button:not(button.react-calendar__tile--range),
  .react-calendar__navigation button {
      color: $black !important;
      background: transparent;
      padding: 0.5rem !important;
  }
}

button.react-calendar__tile--range,
button.react-calendar__tile--rangeStart,
button.react-calendar__tile--rangeEnd {
    color: $white !important;
    padding: 0.5rem !important;
}

.dark button.react-calendar__tile--range,
.dark button.react-calendar__tile--rangeStart,
.dark button.react-calendar__tile--rangeEnd {
    background: map-get($palette, "brand-primary-500"); //.content-color-green
}

.dark button.react-calendar__tile--range,
.dark button.react-calendar__tile--rangeStart,
.dark button.react-calendar__tile--rangeEnd>abbr {
    color: $black; //.content-color-green
}

.light button.react-calendar__tile--range,
.light button.react-calendar__tile--rangeStart,
.light button.react-calendar__tile--rangeEnd {
    background: map-get($palette, "brand-primary-600"); //.content-color-green
}

.light button.react-calendar__tile--range,
.light button.react-calendar__tile--rangeStart,
.light button.react-calendar__tile--rangeEnd>abbr {
    color: $white; //.content-color-green
}


button.react-calendar__tile--rangeStart {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

button.react-calendar__tile--rangeEnd {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

button.react-calendar__tile,
react-calendar__year-view__months__month>abbr {
    text-transform: capitalize;
}

.react-calendar__month-view__days__day--neighboringMonth>abbr {
    color: $gray-600 !important;
}

/* react-date-range - CalendarV2*/
.rdrMonth {
    padding: 0 0.833em;
}

.rdrMonthAndYearWrapper {
    padding-top: 0px;
    height: 35px;
}

.rdrMonthPicker select {
    width: 110px;
}

.rdrMonthAndYearPickers select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='checkboxRound' stroke-linejoin='checkboxRound' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
    background-size: 16px 12px;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-transform: capitalize;
}

/* Days */

.rdrDayPassive .rdrDayNumber span {
    opacity: 0.3;
}

.rdrDayDisabled {
    background-color: inherit;
    cursor: default !important;
}

.rdrDayDisabled .rdrDayNumber span {
    opacity: 0.4;
}

.rdrStartEdge {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.rdrEndEdge {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.rdrDayEndPreview {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.rdrDayStartPreview {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

//Aplications dark and light mode
.rdrCalendarWrapper {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-family: "Inter";
    text-transform: capitalize;
    padding-top: 7px;
  }
  
  /* Dark Mode Styles */
  .dark .rdrCalendarWrapper {
    background-color: #161617;
  }
  
  .dark .rdrDayNumber span {
    color: white;
  }
  
  .dark .rdrDateDisplayItemActive {
    border-color: #232324;
  }
  
  .dark .rdrDateDisplayItemActive input {
    color: #ffffff;
  }
  
  .dark .rdrDateDisplayItem {
    border-radius: 16px;
    background-color: #161617;
    border: 1.5px solid #232324;
  }
  
  .dark .rdrDateDisplayWrapper {
    background-color: #161617;
    border-radius: 8px;
  }
  
  .dark .rdrMonthAndYearPickers select {
    color: #ffffff;
    background-position: right 0rem center !important;
  }
  
  .dark .rdrMonthPicker select option,
  .dark .rdrYearPicker select option {
    background: #161617;
    color: white;
  }
  
  /* Light Mode Styles */
  .light .rdrCalendarWrapper {
    background-color: #ffffff;
  }
  
  .light .rdrDayNumber span {
    color: black;
  }
  
  .light .rdrDateDisplayItemActive {
    border-color: #dcdcdc;
  }
  
  .light .rdrDateDisplayItemActive input {
    color: #000000;
  }
  
  .light .rdrDateDisplayItem {
    border-radius: 16px;
    background-color: #f5f5f5;
    border: 1.5px solid #dcdcdc;
  }
  
  .light .rdrDateDisplayWrapper {
    background-color: #ffffff;
    border-radius: 8px;
  }
  
  .light .rdrMonthAndYearPickers select {
    color: #000000;
    background-position: right 0rem center !important;
  }
  
  .light .rdrMonthPicker select option,
  .light .rdrYearPicker select option {
    background: #ffffff;
    color: black;
  }
  

.calendar-invoices {
  .react-calendar__year-view__months {
    gap: 0.5rem;

    .react-calendar__tile.react-calendar__year-view__months__month {
      border-radius: 8px;
      flex: calc((100% - 16px) / 3) !important;
    }
  }
}
  
.light .calendar-invoices.light .react-calendar__viewContainer button:not(button.react-calendar__tile--range) {
    @include surface-bg-on-surface-alpha-light;
}

.dark .calendar-invoices.dark .react-calendar__viewContainer button:not(button.react-calendar__tile--range) {
    @include surface-bg-on-surface-alpha-dark;
}