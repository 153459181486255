@for $i from 1 through 20 {
    .w-#{$i * 5} {
        width: #{$i * 5%};
    }
}

@for $i from 1 through 1400 {
    .max-width-#{$i} {
        max-width: #{$i}px !important;
    }
}

@for $i from 1 through 1400 {
    .min-width-#{$i} {
        min-width: #{$i}px !important;
    }
}

@for $i from 1 through 1400 {
    @media (min-width: 1350px) {
      .max-width-#{$i}-xl {
        max-width: #{$i}px !important;
      }
    }
  }

@for $i from 1 through 1400 {
    @media (min-width: 1550px) {
      .max-width-#{$i}-2xl {
        max-width: #{$i}px !important;
      }
    }
  }