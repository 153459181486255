/* Design system */

/* black */
$black: #000000;
$black-opacity: #000000cc;

/* White */
$white: #ffffff;

/* Neutral*/
$neutral-50: #f8fafc;
$neutral-100: #f1f5f9;
$neutral-200: #e2e8f0;
$neutral-300: #cbd5e1;
$neutral-400: #94a3b8;
$neutral-500: #64748b;
$neutral-600: #475569;
$neutral-700: #334155;
$neutral-800: #1e293b;
$neutral-900: #0f172a;
$neutral-950: #020617;

/* Gray */
$gray-50: #fafafa;
$gray-90: #e8e8e8; //REMOVE -> IT IS NOT IN THE NEW TOKENS
$gray-95: #f3f3f3; //REMOVE -> IT IS NOT IN THE NEW TOKENS
$gray-100: #f5f5f5;
$gray-200: #e5e5e5;
$gray-300: #d4d4d4;
$gray-400: #a3a3a3;
$gray-450: #232324;
$gray-500: #161617; //THIS WAS LEFT WITH THE PREVIOUS COLOR TOKEN -> 737373
$gray-550: #1f1f20;//REMOVE -> IT IS NOT IN THE NEW TOKENS
$gray-600: #525252;
$gray-650: #454545;//REMOVE -> IT IS NOT IN THE NEW TOKENS
$gray-700: #404040;
$gray-800: #262626;
$gray-850: #0d0d0e;//REMOVE -> IT IS NOT IN THE NEW TOKENS
$gray-900: #171717;
$gray-950: #141414;
$gray-1000 : #777777;
$gray-old : #161617;

//////////////////////////////////
/* Green */
$green-50: #f9ffe6;
$green-200: #e6fe9a;
$green-300: #abd13150;
$green-500: #cefc34; 
$green-550: #a1c52a;
$green-800: #4d6501;
$green-900: #273201;

//////////////////////////////////
/* Blue */
$blue-50: #ebfeff;
$blue-100: #cefcff;
$blue-200: #a2f6ff;
$blue-300: #63edfd;
$blue-400: #1cdaf4;
$blue-500: #00b7d3;
$blue-600: #0396b7;
$blue-700: #0a7894;
$blue-800: #126078;
$blue-900: #145065;
$blue-950: #063546;

/* Red */
$red-30: #fd8686;//REMOVE -> IT IS NOT IN THE NEW TOKENS
$red-40: #fd5d5d;//REMOVE -> IT IS NOT IN THE NEW TOKENS
$red-50 : #FEF2F3;
$red-100:  #FEE2E3;
$red-200:  #FECACC;
$red-300:  #FCA5A9;
$red-400:  #F87177;
$red-500:  #EF444C;
$red-600:  #DC262F;
$red-700:  #B91C23;
$red-800:  #991B21;
$red-900:  #7F1D22;
$red-950:  #450A0D;

/* Orange */
$orange-50:#fff7ed;
$orange-100:#ffedd5;
$orange-200: #fed7aa;
$orange-300: #fdba74;
$orange-400: #fb923c;
$orange-500: #f97316;
$orange-600: #ea580c;
$orange-700: #c2410c;
$orange-800: #9a3412;
$orange-900: #7c2d12;
$orange-950: #431407;

/* Purple */
$purple-50: #faf5ff;
$purple-100: #f3e8ff;
$purple-200: #e9d5ff;
$purple-300: #d8b4fe;
$purple-400: #c084fc;
$purple-500: #a855f7;
$purple-600: #9333ea;
$purple-700: #7e22ce;
$purple-800: #6b21a8;
$purple-900: #581c87;
$purple-950: #3b0764;

/* Pink */
$pink-50: #fdf2f8;
$pink-100: #fce7f3;
$pink-200: #fbcfe8;
$pink-300: #f9a8d4;
$pink-400: #f472b6;
$pink-500: #ec4899;
$pink-600: #db2777;
$pink-700: #be185d;
$pink-800: #9d174d;
$pink-900: #831843;
$pink-950: #500724;

$alpha-white-4: #ffffff0a;
$alpha-white-6: #ffffff0f;
$alpha-white-8: #ffffff14;
$alpha-white-10: #ffffff1A;
$alpha-white-32: #ffffff52;
$alpha-white-64: #ffffffa3;
$alpha-white-100: #303031;

$alpha-black-4: #1414140a;
$alpha-black-6: #1414140f;   
$alpha-black-8: #14141414;
$alpha-black-10: #1414141A;
$alpha-black-16: #14141429;
$alpha-black-32: #14141452;
$alpha-black-64: #141414a3;


$alpha-primary-brand-8: #9ecc1414;
$alpha-primary-brand-32: #9ecc1451;

$alpha-blue-8: #00b7d314;
$alpha-blue-32: #00b7d351;

$alpha-orange-8: #f9731614;
$alpha-orange-32: #f9731651;

$alpha-red-8: #9f1239;
$alpha-red-32: #ef444451;

$alpha-purple-8: #a855f714;
$alpha-purple-32: #a855f751;

$alpha-pink-8: #ec489914;
$alpha-pink-32: #ec489951;

$brand-primary-50: #fafde7;
$brand-primary-100: #f3fcca;
$brand-primary-200: #e7f99f;
$brand-primary-300: #d1f362;
$brand-primary-400: #bde732;
$brand-primary-500: #9ecc14;
$brand-primary-600: #7da60c;
$brand-primary-700: #5d7c0e;
$brand-primary-800: #4c6510;
$brand-primary-900: #3f5412;
$brand-primary-950: #202e05;

$brand-green-50: #effce9;
$brand-green-100: #dcf7d0;
$brand-green-200: #bcf0a6;
$brand-green-300: #92e472;
$brand-green-400: #6dd546;
$brand-green-500: #45a824;
$brand-green-600: #38951b;
$brand-green-700: #2d7219;
$brand-green-800: #285a1a;
$brand-green-900: #244d1a;
$brand-green-950: #0e2a09;

$brand-yellow-50: #fcfce3;
$brand-yellow-100: #f6fac2;
$brand-yellow-200: #f5f68d;
$brand-yellow-300: #f2f250;
$brand-yellow-400: #ebe81e;
$brand-yellow-500: #e3dd16;
$brand-yellow-600: #cdc00e;
$brand-yellow-700: #a9990f;
$brand-yellow-800: #8e7a15;
$brand-yellow-900: #7b6119;
$brand-yellow-950: #4c3b0b;

$palette: (
  "black": $black,
  "black-opacity": $black-opacity,
  "white": $white,
  "neutral-50": $neutral-50,
  "neutral-100": $neutral-100,
  "neutral-200": $neutral-200,
  "neutral-300": $neutral-300,
  "neutral-400": $neutral-400,
  "neutral-500": $neutral-500,
  "neutral-600": $neutral-600,
  "neutral-700": $neutral-700,
  "neutral-800": $neutral-800,
  "neutral-900": $neutral-900,
  "alpha-white-4": $alpha-white-4,
  "alpha-white-6": $alpha-white-6,
  "alpha-white-8": $alpha-white-8,
  "alpha-white-10": $alpha-white-10,
  "alpha-white-32": $alpha-white-32,
  "alpha-white-64": $alpha-white-64,
  "alpha-white-100": $alpha-white-100,
  "alpha-black-4": $alpha-black-4,
  "alpha-black-6": $alpha-black-4,
  "alpha-black-8": $alpha-black-8,
  "alpha-black-16": $alpha-black-16,
  "alpha-black-10": $alpha-black-10,
  "alpha-black-32": $alpha-black-32,
  "alpha-black-64": $alpha-black-64,
  "alpha-primary-brand-8": $alpha-primary-brand-8,
  "alpha-primary-brand-32": $alpha-primary-brand-32,
  "alpha-orange-8": $alpha-orange-8,
  "alpha-orange-32": $alpha-orange-32,
  "alpha-red-8": $alpha-red-8,
  "alpha-red-32": $alpha-red-32,
  "brand-primary-200": $brand-primary-200,
  "brand-primary-300": $brand-primary-300,
  "brand-primary-400": $brand-primary-400,
  "brand-primary-500": $brand-primary-500,
  "brand-primary-600": $brand-primary-600,
  "brand-primary-700": $brand-primary-700,
  "gray-50": $gray-50,
  "gray-90": $gray-90,
  "gray-95": $gray-95,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-450": $gray-450,
  "gray-500": $gray-500,
  "gray-550": $gray-550,
  "gray-600": $gray-600,
  "gray-650": $gray-650,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-850": $gray-850,
  "gray-900": $gray-900,
  "gray-950": $gray-950,
  "green-200": $green-200,
  "green-300": $green-300,
  "green-500": $green-500,
  "green-550": $green-550,
  "green-800": $green-800,
  "green-900": $green-900,
  "blue-100": $blue-100,
  "blue-200": $blue-200,
  "blue-400": $blue-400,
  "blue-500": $blue-500,
  "blue-600": $blue-600,
  "blue-700": $blue-700,
  "blue-800": $blue-800,
  "blue-900": $blue-900,
  // "red-30": $red-30,
  // "red-40": $red-40,
  "red-200": $red-200,
  "red-400": $red-400,
  "red-500": $red-500,
  "red-600": $red-600,
  "red-700": $red-700,
  "red-800": $red-800,
  "orange-200": $orange-200,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "purple-200": $purple-200,
  "purple-400": $purple-400,
  "purple-500": $purple-500,
  "purple-600": $purple-600,
  "purple-700": $purple-700,
  "purple-800": $purple-800,
  "pink-200": $pink-200,
  "pink-400": $pink-400,
  "pink-500": $pink-500,
  "pink-600": $pink-600,
  "pink-700": $pink-700,
  "pink-800": $pink-800,
  "brand-primary-800": $brand-primary-800
);

@mixin export-colors-to-root($color-map) {
  :root {
    @each $name, $value in $color-map {
      --#{$name}: #{$value};
    }
  }
}

@include export-colors-to-root($palette);
