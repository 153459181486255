@mixin border-fill-regular-light {
    border: 1px solid $alpha-black-32;
}

@mixin border-fill-regular-dark {
    border: 1px solid $alpha-white-32;
}

// Light Mode
.border-fill-bold.light {
    border: 1px solid $black;
}
.border-fill-regular.light {
    @include border-fill-regular-light;
}
.border-fill-regular-top.light {
    border: 1px solid $alpha-black-32;
}
.border-fill-medium.light {
    border: 1px solid $alpha-black-10 
}
.border-fill-light.light {
    border: 1px solid $alpha-black-8 ; 
}
.border-fill-medium-dashed.light {
    border: 0.5px dashed $alpha-black-10; 
}
.border-fill-light-right.light {
    border-right: 1px solid $alpha-black-8; 
}
.border-fill-medium-top.light{
    border-top: 1px solid $alpha-black-10 ;
}
.border-fill-medium-bottom.light{
    border-bottom: 1px solid $alpha-black-10; 
}
.border-fill-medium-left.light{
    border-left: 1px solid $alpha-black-10; 
}
.border-fill-medium-right.light{
    border-right: 1px solid $alpha-black-10; 
}
.border-fill-medium-vertical.light{
    border-top: 1px solid $alpha-black-10; 
    border-bottom: 1px solid $alpha-black-10; 
}
.border-fill-medium-horizontal.light{
    border-left: 1px solid $alpha-black-10; 
    border-right: 1px solid $alpha-black-10; 
}

/* Dark oscuro */
.border-fill-bold.dark {
    border: 1px solid $white ; 
}
.border-fill-regular.dark {
    @include border-fill-regular-dark;
}
.border-fill-regular-top.dark {
    border-top: 1px solid $alpha-white-32;
}
.border-fill-medium.dark {
    border: 1px solid $alpha-white-10;
}
.border-fill-light.dark {
    border: 1px solid $alpha-white-8;
}
.border-fill-medium-dashed.dark {
    border: 0.5px dashed $alpha-white-10;
}
.border-fill-light-right.dark {
    border-right: 1px solid $alpha-white-8;
}
.border-fill-medium-top.dark{
    border-top: 1px solid $alpha-white-10;
}
.border-fill-medium-bottom.dark{
    border-bottom: 1px solid $alpha-white-10; 
}
.border-fill-medium-left.dark{
    border-left: 1px solid $alpha-white-10; 
}
.border-fill-medium-right.dark{
    border-right: 1px solid $alpha-white-10; 
}
.border-fill-medium-vertical.dark{
    border-top: 1px solid $alpha-white-10;
    border-bottom: 1px solid $alpha-white-10;
}
.border-fill-medium-horizontal.dark{
    border-left: 1px solid $alpha-white-10;
    border-right: 1px solid $alpha-white-10;
}

// Surface
.border-surface-bg-on-surface.dark {
    border: 1px solid $alpha-white-6; 
}
.border-surface-bg-on-surface.light {
    border: 1px solid $alpha-white-6;
}


// Border colors
.border-color-green.light{
    border: 1px solid $brand-primary-600;
}
.border-color-blue.light{
    border: 1px solid $blue-600;
}
.border-color-red.light{
    border: 1px solid $red-600;
}
.border-color-orange.light{
    border: 1px solid $orange-600;
}

.border-color-green.dark{
    border: 1px solid $brand-primary-500;
}
.border-color-blue.dark{
    border: 1px solid $blue-500;
}
.border-color-red.dark{
    border: 1px solid $red-500;
}
.border-color-orange.dark{
    border: 1px solid $orange-500;
}
 


