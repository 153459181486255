@font-face {
    font-family: "SpaceGrotesk";
    src: url(../fonts/SpaceGrotesk.ttf);
}

@font-face {
    font-family: "Sora";
    src: url(../fonts/Sora.ttf);
}

@font-face {
    font-family: "Inter";
    src: url(../fonts/Inter.ttf);
}

@each $color,
$value in $palette {
    .txt-#{$color} {
        color: $value  !important;
    }
}

@each $color,
$value in $palette {
    .txt-#{$color}-hover:hover {
        color: $value  !important;
    }
}

.txt-title {
    font-family: "Sora"
}

.txt-phrase {
    font-family: "Inter"
}

.txt-bold {
    @include font-ellipsis;
    font-weight: 600;
    cursor: default;
}

.txt-semibold {
    @include font-ellipsis;
    font-weight: 400;
    cursor: default;
}

.txt-medium {
    @include font-ellipsis;
    font-weight: 300;
    cursor: default;
}

.txt-regular {
    @include font-ellipsis;
    font-weight: 200;
    cursor: default;
}

.txt-overflow-unset {
    text-overflow: unset;
}

.prevent-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.txt-capitalize{
    text-transform: capitalize;
}

.txt-wrap-pretty {
    text-wrap: pretty;
}

.txt-truncate {
    @include font-ellipsis
}