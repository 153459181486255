@mixin surface-bg-on-surface-alpha-light {
    background-color: map-get($palette, "alpha-black-4");
}

@mixin surface-bg-on-surface-alpha-dark {
    background-color: map-get($palette, "alpha-white-4"); 
}

// Tema claro
.surface-bg-fill.light {
    background-color: map-get($palette, "gray-100"); 
}
.surface-bg-surface.light {
    background-color: map-get($palette, "white"); 
}
.surface-bg-on-surface.light {
    background-color: map-get($palette, "gray-50"); 
}
.hover-surface-bg-on-surface.light {
    &:hover {
        background-color: map-get($palette, "gray-50"); 
    }
}
.surface-bg-on-surface-alpha.light {
    @include surface-bg-on-surface-alpha-light;
}
.surface-on-surface-hover.light {
    background-color: map-get($palette, "alpha-black-10"); 
}
.surface-bg-blackout.light {
    background-color: map-get($palette, "alpha-black-16"); 
}

// Tema oscuro
.surface-bg-fill.dark {
    background-color: map-get($palette, "black"); 
}
.surface-bg-surface.dark {
    background-color: map-get($palette, "gray-950"); 
}
.surface-bg-on-surface.dark {
    background-color: map-get($palette, "gray-800"); 
}
.hover-surface-bg-on-surface.dark {
    &:hover {
        background-color: map-get($palette, "gray-800"); 
    }
}
.surface-bg-on-surface-alpha.dark {
    @include surface-bg-on-surface-alpha-dark;
}
.surface-on-surface-hover.dark {
    background-color: map-get($palette, "alpha-white-10");
}
.surface-bg-blackout.dark {
    background-color: map-get($palette, "alpha-black-64"); 
}

